import React from "react";
import Layout from "../../../../other/layout";
import SellSheets from "../../../../components/AC-StaticPages/sales-resources/sales-material/sell-sheets/SellSheets";
import SEO from "../../../../other/seo";

export default function sellSheets() {
  return (
    <Layout>
      <SEO title="Sell Sheets" />

      <SellSheets />
    </Layout>
  );
}
