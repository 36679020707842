import React from "react";
import { Link } from "gatsby";
import cheque_management from "../../../../../assets/img/TY25P717_ChequesBanking_Thumbnail.jpg";
import cash_management from "../../../../../assets/img/TY25BP3441_BankingSS1C_Thumbnail.jpg";
import business_forms from "../../../../../assets/img/TY25BF3441_FormsSS1C_Thumbnail.jpg";
import vertical from "../../../../../assets/img/TY25MS3441_FormsSS2C_Thumbnail.jpg";
import automotive from "../../../../../assets/img/TTN64601A_Auto_Thumbnail.jpg";
import restaurant from "../../../../../assets/img/TTN64601R_Rest_Thumbnail.jpg";
import retail from "../../../../../assets/img/TTN64601T_Retail_Thumbnail.jpg";
import contractor from "../../../../../assets/img/TTN64601C_Contr_Thumbnail.jpg";

import styles from "../../../style/styles.module.css";

export default function SalesMaterials() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Sell Sheets</h1>
          <h2>Printed sales materials to close deals.</h2>
          <p className={styles.productMargin}>
            DFS offers FREE digital sell sheets to help you sell products
            easily. Simply add your contact information, then distribute to
            customers and prospects, use on sales calls, place on front counters
            or use them as a convenient reference when making a sale.
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.content}>
          <div className={styles.cards}>
            <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/ChequesBankingSS2C_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img
                  src={cheque_management}
                  alt="Cheques and cash management products"
                ></img>
                <h3>Cheques and Cash Management Products</h3>
                (opens in new window)
              </a>
            </div>
            <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/BankingSS1C_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={cash_management} alt="Cash management products"></img>
                <h3>Cash Management Products</h3>
                (opens in new window)
              </a>
            </div>
            <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/FormsSS1C_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={business_forms} alt="Business forms"></img>
                <h3>Business Forms</h3>
                (opens in new window)
              </a>
            </div>
            <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/FormsSS2C_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={vertical} alt="Vertical market products"></img>
                <h3>Vertical Market Products</h3>
                (opens in new window)
              </a>
            </div>
            {/*   <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/Auto_TTN64601A_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={automotive} alt="Automotive products"></img>
                <h3>Automotive Products</h3>
                (opens in new window)
              </a>
            </div> */}
            {/* <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/Rest_TTN64601R_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={restaurant} alt="Restaurant Products"></img>
                <h3>Restaurant Products</h3>
                (opens in new window)
              </a>
            </div> */}
            {/*   <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/Retail_TTN64601T_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={retail} alt="Retail Products"></img>
                <h3>Retail Products</h3>
                (opens in new window)
              </a>
            </div> */}
            {/*    <div className={styles.card}>
              <a
                href="https://www.dfsonline.ca/shop/store/20090722001/assets/pdf/Contr_TTN64601C_Editable_Sell_Sheet.pdf"
                target="_blank"
              >
                <img src={contractor} alt="Contractor Products"></img>
                <h3>Contractor Products</h3>
                (opens in new window)
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
